<template>
  <div>
    <!-- Description -->
    <div v-bind:id="componentId" class="form-group mb-3">
      <div class="row">
        <div class="col">
          <label
            v-bind:for="componentId + '-text'"
            class="custom-label"
            v-bind:class="{
              'text-danger': textRequired && $v.computedData.text.$error,
            }"
            >{{ title }}</label
          >
        </div>
        <div
          class="col-auto"
          v-if="textRequired && $v.computedData.text.$error"
        >
          <div
            class="text-danger ml-auto"
            v-if="!$v.computedData.text.required"
          >
            <small
              ><i>{{ $t("requiredArea") }}</i></small
            >
          </div>
        </div>
      </div>
      <WeEditor
        v-bind:name="name"
        v-bind:accessToken="accessToken"
        v-model="computedData.text"
      />
    </div>
    <!-- ./Description -->

    <!-- Description Toggle -->
    <div v-if="!compact" class="form-group mb-3">
      <label class="custom-label d-block">{{ $t("showDescription") }}</label>
      <div class="card">
        <div class="card-body">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              v-bind:id="componentId + '-show-true'"
              class="custom-control-input"
              v-model="computedData.show"
              v-bind:value="true"
            />
            <label
              class="custom-control-label"
              v-bind:for="componentId + '-show-true'"
              >{{ $t("yes") }}</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              v-bind:id="componentId + '-show-false'"
              class="custom-control-input"
              v-model="computedData.show"
              v-bind:value="false"
            /><label
              class="custom-control-label"
              v-bind:for="componentId + '-show-false'"
              >{{ $t("no") }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <!-- ./Description Toggle -->

    <!-- Description Toggle Top-Bottom -->
    <div v-if="!compact && computedData.show" class="form-group mb-3">
      <label class="custom-label d-block">{{
        $t("descriptionPosition")
      }}</label>
      <div class="card">
        <div class="card-body">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              v-bind:id="componentId + '-place-before'"
              class="custom-control-input"
              v-model="computedData.place"
              v-bind:value="'before'"
            />
            <label
              class="custom-control-label"
              v-bind:for="componentId + '-place-before'"
              >{{ $t("beforeContent") }}</label
            >
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              v-bind:id="componentId + '-place-after'"
              class="custom-control-input"
              v-model="computedData.place"
              v-bind:value="'after'"
            />
            <label
              class="custom-control-label"
              v-bind:for="componentId + '-place-after'"
              >{{ $t("afterContent") }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <!-- ./Description Toggle Top-Bottom -->
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "WeDescription",
  validations() {
    if (this.textRequired) {
      return {
        computedData: {
          text: {
            required,
          },
        },
      };
    } else {
      return {};
    }
  },
  props: {
    data: {
      text: {
        default: "",
      },
      show: {
        default: "",
      },
      place: {
        default: "before",
      },
    },
    textRequired: {
      default: false,
    },
    compact: {
      default: "",
    },
    name: {
      default: "",
    },
    accessToken: {
      default: "",
    },
    title: {
      default: "",
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    componentId() {
      return "wedescription-" + helper.getRandomInteger(1, 999999);
    },
    computedData() {
      return this.data;
    },
  },
};
</script>

<style lang="scss">
.custom-control-label {
  cursor: pointer;
}
.ck-editor__editable_inline {
  min-height: 250px;
}
</style>
